import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, notAuthGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
    canActivate: [notAuthGuard],
  },
  {
    path: '',
    loadChildren: () => import('./static/static.module').then((m) => m.StaticModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./cil/cil.module').then((m) => m.CilModule),
    canActivate: [authGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
