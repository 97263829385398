import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { AuthService } from '../shared/services/auth.service';
import { LoginSuccess } from './auth.state';

@Injectable({ providedIn: 'root' })
export class AuthHandler {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {
    this.actions$.pipe(ofActionDispatched(LoginSuccess)).subscribe(() => {
      this.authService.auth(true).subscribe();
    });
  }
}
