import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { AuthState } from '../../state/auth.state';

export const authGuard: CanActivateFn = (): Observable<boolean> | UrlTree => {
  const router = inject(Router);
  const store = inject(Store);
  const isAuth = !!store.selectSnapshot(AuthState.loggedUser);

  if (isAuth) {
    return of(true);
  }

  return router.parseUrl('/login');
};

export const notAuthGuard: CanActivateFn = (): boolean | UrlTree => {
  const store = inject(Store);
  const isAuth = !!store.selectSnapshot(AuthState.loggedUser);
  const router = inject(Router);

  if (!isAuth) {
    return true;
  }

  return router.parseUrl('/app');
};
