import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, provideZoneChangeDetection } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule, TextfieldModule } from '@ed---interne/ng-uui-components';
import { NgxsModule } from '@ngxs/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeAppFactory } from './app.initializer';
import { PublicModule } from './public/public.module';
import { AuthService } from './shared/services/auth.service';
import { AuthHandler } from './state/auth.handler';
import { AuthState } from './state/auth.state';

import localeFr from '@angular/common/locales/fr';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS } from '@angular/material-date-fns-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { fr } from 'date-fns/locale';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './auth.interceptor';
import { AccountService } from './cil/settings/account/account.service';
import { PresentationLayoutComponent } from './public/layouts/presentation-layout/presentation-layout.component';
import { AdemeService } from './shared/services/ademe.service';
import { EdApiService } from './shared/services/ed-api.service';
import { ExportCsvService } from './shared/services/export-csv.service';

registerLocaleData(localeFr);

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, PresentationLayoutComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TextfieldModule,
    PublicModule,
    NgxsModule.forRoot([AuthState]),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    AlertModule,
    MatNativeDateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: MAT_DATE_LOCALE, useValue: fr },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    AuthService,
    AccountService,
    AdemeService,
    EdApiService,
    ExportCsvService,
    provideEnvironmentNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, AuthHandler, TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
